import React, { Component } from 'react';
import { motion } from "framer-motion"
import carte_de_voeux from '../assets/carte de voeux 2025.jpeg';

const divContainerVariants = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.25,
        },
    }
}

const divSquareVariants = {
    hidden: { opacity: 0 }, show: { opacity: 1 }
}

const CarteDeVoeux = () => {
    return (
        <>

            <div className="ciruit-patient">
                <div>
                    <motion.img whileHover={{ scale: 0.9 }}
                        whileTap={{ scale: 0.9 }} className=" flex-auto px-1 " src={carte_de_voeux} alt="hero" />
                </div>
            </div>


        </>
    );
}
export default CarteDeVoeux;
