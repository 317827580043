import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import embleme_sante from '../assets/LOGO-MSANP-VF.png';
import logo_chu_anosiala from '../assets/Logo_wihtout_text.png';
import { motion } from "framer-motion"
import { Link } from 'react-router-dom';

const Navbar1 = () => {
  // State to manage the navbar's visibility
  const [nav, setNav] = useState(false);

  // Toggle function to handle the navbar's display
  const handleNav = () => {
    setNav(!nav);
  };

  // Array containing navigation items
  const navItems = [
    { id: 1, text: 'Actualités', menu: 'Actualites' },
    { id: 2, text: 'Accueil' , menu: '/'},
    { id: 3, text: 'Services', menu: 'Services'},
    { id: 4, text: 'Contact', menu: 'Contact' },
  ];



  return (
    <div className='bg-[#026d77] flex justify-between items-center h-24 w-full mx-auto px-4 text-white font-serif z-50 sticky top-0'>
      {/* Logo */}
      <div class="flex ">
        <img className="h-15 w-20 my-auto " src={embleme_sante} alt="hero" />
        <h1 className='hidden text-sm md:flex w-full md:text-2xl lg:text-3xl font-bold my-auto mx-4 text-red-600'>CHU ANOSIALA</h1>
        <img className="hidden md:flex h-15 w-20 my-auto mx-3" src={logo_chu_anosiala} alt="hero" />
        
      </div>


      <motion.div
          className="overflow-hidden whitespace-nowrap"
          animate={{ x: [0, -50,0] }} // La valeur -2000 est ajustable selon la largeur de votre texte
          transition={{ repeat: Infinity, duration: 20, ease: "linear" }}
        >
          <span className=" text-xs md:text-xl text-gray-100 font-bold">Isika miaraka mba ho samy salama!</span>
        </motion.div>

      {/* Desktop Navigation */}
      <ul className='hidden md:flex whitespace-nowrap'>
        {navItems.map(item => (
          <li
            key={item.id}
            className='p-4 text-red-400 font-bold hover:bg-[#f4f3f3] rounded-xl m-2 cursor-pointer duration-300 hover:text-black'
          >
            <Link to={item.menu} className="navbar-link">{item.text}</Link>
          </li>
        ))}
      </ul>

      {/* Mobile Navigation Icon */}
      <div onClick={handleNav} className='block md:hidden'>
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      {/* Mobile Navigation Menu */}
      <ul
        className={
          nav
            ? 'fixed md:hidden left-0 top-0 w-[60%] h-100 border-r border-r-gray-900 bg-[#026d77] ease-in-out duration-500'
            : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]'
        }
      >
        {/* Mobile Logo */}
        <h1 className='w-full text-xl  text-red-300 my-4'>CHU Anosiala</h1>

        {/* Mobile Navigation Items */}
        {navItems.map(item => (
          <li
            key={item.id}
            className='p-1 border-b rounded-xl hover:bg-[#f87171] duration-300 hover:text-black cursor-pointer border-gray-600'
          >
            <Link to={item.menu} className="navbar-link">{item.text}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Navbar1;